import "./footer.styles.scss";

const Footer = () => {
  return (
    <div className="footer-container">
      <span>2022 - Tools Network</span>
      <p>
        Website by{" "}
        <a href="https://github.com/stairan" target="_blank">
          stairan
        </a>
      </p>
    </div>
  );
};

export default Footer;
