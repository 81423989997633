import { Form, Button } from "react-bootstrap";
import { useState } from "react";
import { Buffer } from "buffer";

import CustomHeader from "../../components/custom-header/custom-header";

import "./hex-to-text.styles.scss";

const HexToText = () => {
  const [text, setText] = useState("");
  const [stringText, setStringText] = useState("");

  const onTextChange = (event) => {
    const removedSpace = event.target.value.replace(/\s+/g, "");
    setText(removedSpace);
    const output = Buffer.from(removedSpace, "hex");
    setStringText(output);
  };

  return (
    <div className="hex-to-string-container">
      <CustomHeader title="Hex To Text" />
      <h1>Hex To Text String</h1>
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Hex:</Form.Label>
          <Form.Control as="textarea" rows={5} onChange={onTextChange} />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Text:</Form.Label>
          <Form.Control as="textarea" rows={5} disabled value={stringText} />
        </Form.Group>
      </Form>
      <Button
        variant="primary"
        onClick={() => {
          navigator.clipboard.writeText(stringText);
        }}
      >
        Copy text
      </Button>
    </div>
  );
};

export default HexToText;
