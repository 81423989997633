import { Routes, Route } from "react-router-dom";
import ReactGA from "react-ga4";

import Navigation from "./routes/navigation/navigation.component";
import Colorizer from "./routes/colorizer/colorizer.component";
import Toonify from "./routes/toonify/toonify.component";
import HexToText from "./routes/hex-to-text/hex-to-text.component";
import TextToHex from "./routes/text-to-hex/text-to-hex.component";
import Sherlock from "./routes/sherlock/sherlock.component";
import Home from "./routes/home/home.component";
import Footer from "./components/footer/footer.component";

import "./App.scss";
import RouteChangeTracker from "./components/route-change-tracker/route-change-tracker.component";

function App() {
  return (
    <div className="app-container">
      <div className="content-wrap">
        <RouteChangeTracker />
        <Routes>
          <Route path="/" element={<Navigation />}>
            <Route index element={<Home />} />
            <Route exact path="/colorizer" element={<Colorizer />} />
            <Route exact path="/toonify" element={<Toonify />} />
            <Route exact path="/hex-to-string" element={<HexToText />} />
            <Route exact path="/string-to-hex" element={<TextToHex />} />
            <Route exact path="/sherlock" element={<Sherlock />} />
          </Route>
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
