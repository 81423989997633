import { useState } from "react";
import "./toonify.styles.scss";

import FileUploader from "../../components/file-uploader/file-uploader.component";
import CustomHeader from "../../components/custom-header/custom-header";

const COLORIZER_URL = "https://api.deepai.org/api/toonify";
const DEEPAI_KEY = "ace8252d-f15e-4dfe-a92d-ee90fa06da0c";

const Toonify = () => {
  const [colorizedFile, setColorizedFile] = useState();

  const handleResult = (response) => {
    setColorizedFile(response.data.output_url);
  };

  return (
    <div className="toonify-content">
      <CustomHeader title="Toonify" />
      <h1>Free AI Toonify</h1>
      <p>Turn a photo of any face into a cartoon style image</p>

      <div id="upload">
        <h3>Want to Toonify a photo?</h3>
        <FileUploader
          buttonText="Toonify"
          uploadUrl={COLORIZER_URL}
          apiKey={DEEPAI_KEY}
          label="Just select your image file and press Toonify!"
          resultHandler={handleResult}
        />

        {colorizedFile && (
          <div className="finished-container">
            <img src={colorizedFile} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Toonify;
