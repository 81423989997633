import { useState } from "react";

import "./colorizer.styles.css";

import FileUploader from "../../components/file-uploader/file-uploader.component";
import CustomHeader from "../../components/custom-header/custom-header";

const COLORIZER_URL = "https://api.deepai.org/api/colorizer ";
const DEEPAI_KEY = "ace8252d-f15e-4dfe-a92d-ee90fa06da0c";

const Colorizer = () => {
  const [colorizedFile, setColorizedFile] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const handleResult = (response) => {
    if (response.status === 200) {
      setColorizedFile(response.data.output_url);
    } else {
      setErrorMessage(
        "Something went wrong, please try again later! " + response.statusText
      );
    }
  };

  return (
    <div className="colorizer-content">
      <CustomHeader title="AI Photo Colorizer" />
      <h1>Free AI Photo Colorizer</h1>
      <p>Colorize your old black and white photos for free</p>

      <div id="upload">
        <h3>Want to colorize a photo?</h3>
        <FileUploader
          buttonText="Colorize"
          uploadUrl={COLORIZER_URL}
          apiKey={DEEPAI_KEY}
          label="Just select your image file and press Colorize!"
          resultHandler={handleResult}
        />

        {colorizedFile && (
          <div className="finished-container">
            <img src={colorizedFile} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Colorizer;
