import { useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import axios from "axios";

const FileUploader = ({
  buttonText,
  label,
  uploadUrl,
  resultHandler,
  apiKey,
}) => {
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const fileSelected = (event) => {
    console.log(event.target.files[0]);
    setFile(event.target.files[0]);
  };

  const handleUpload = (event) => {
    event.preventDefault();
    setErrorMessage(null);
    setLoading(true);

    const url = uploadUrl;
    const formData = new FormData();
    formData.append("image", file);
    const config = {
      headers: {
        "Content-Type": "image/jpeg",
        "api-key": apiKey,
      },
    };
    axios
      .post(url, formData, config)
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        resultHandler(response);
        if (response.status !== 200) {
          setErrorMessage(
            "Something went wrong, please try again later! " +
              response.statusText
          );
        }
      })
      .catch(function (error) {
        setLoading(false);
        setErrorMessage(
          "Something went wrong, please try again later! " + error.message
        );
      });
  };

  return (
    <Form onSubmit={handleUpload} className="upload-form">
      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>{label}</Form.Label>
        <Form.Control
          type="file"
          required
          onChange={fileSelected}
          accept="image/*"
        />
      </Form.Group>
      {loading ? (
        <Button variant="primary" disabled>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          <span className="visually-hidden">Loading...</span>
        </Button>
      ) : (
        <Button type="submit">{buttonText}</Button>
      )}
      {errorMessage && <p>{errorMessage}</p>}
    </Form>
  );
};

export default FileUploader;
