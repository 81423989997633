import { Card, Button, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import CustomHeader from "../../components/custom-header/custom-header";

import ColorizerThumb from "../../assets/colorizer_thumb.jpg";
import ToonifyThumb from "../../assets/toonify_thumb.jpg";
import HexTextThumb from "../../assets/hex_text_thumb.jpg";
import TextHexThumb from "../../assets/text_hex_thumb.jpg";
import SherlockThumb from "../../assets/sherlock_thumb.jpg";
import "./home.styles.scss";

const Home = () => {
  const navigate = useNavigate();

  return (
    <div className="home-container">
      <CustomHeader title="Home" />
      <h1>What is Tools Network?</h1>
      <p>
        Tools Network is a collection of free to use online tools, that I often
        use during work. Originally I always had to search for such tools when I
        needed them, so I decided why not collect them on one website, where I
        can quickly reach them. I made it public so anyone can use the tools.
      </p>

      <div className="cards-container">
        <Row xs={1} md={2} lg={3}>
          <Col>
            <Card bg="light" text="dark" className="single-card">
              <Card.Body>
                <Card.Title>AI Photo Colorizer</Card.Title>
                <Card.Img src={ColorizerThumb} alt="Colorizer example" />
                <Card.Text>
                  Colorize your old black and white photos for free using
                  artificial intelligence.
                </Card.Text>
                <Button
                  variant="primary"
                  onClick={() => {
                    navigate("/colorizer");
                  }}
                >
                  Open
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card bg="light" text="dark" className="single-card">
              <Card.Body>
                <Card.Title>AI Photo Toonify</Card.Title>
                <Card.Img src={ToonifyThumb} alt="Toonify example" />
                <Card.Text>
                  Turn your portrait photos into cartoon style for free using
                  artificial intelligence.
                </Card.Text>
                <Button
                  variant="primary"
                  onClick={() => {
                    navigate("/toonify");
                  }}
                >
                  Open
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card bg="light" text="dark" className="single-card">
              <Card.Body>
                <Card.Title>Hex To Text</Card.Title>
                <Card.Img src={HexTextThumb} alt="Hex To Text Thumbnail" />
                <Card.Text>
                  Decode hexadecimal text to make it understandable for human
                  eyes.
                </Card.Text>
                <Button
                  variant="primary"
                  onClick={() => {
                    navigate("/hex-to-string");
                  }}
                >
                  Open
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card bg="light" text="dark" className="single-card">
              <Card.Body>
                <Card.Title>Text To Hex</Card.Title>
                <Card.Img src={TextHexThumb} alt="Text To Hex Thumbnail" />
                <Card.Text>
                  Encode regular text into hexadecimal format text.
                </Card.Text>
                <Button
                  variant="primary"
                  onClick={() => {
                    navigate("/string-to-hex");
                  }}
                >
                  Open
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card bg="light" text="dark" className="single-card">
              <Card.Body>
                <Card.Title>Sherlock</Card.Title>
                <Card.Img src={SherlockThumb} alt="Text To Hex Thumbnail" />
                <Card.Text>
                  Find usernames across many social networks.
                </Card.Text>
                <Button
                  variant="primary"
                  onClick={() => {
                    navigate("/sherlock");
                  }}
                >
                  Open
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Home;
