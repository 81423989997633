import { Navbar, Nav, Container } from "react-bootstrap";
import { Outlet, Link } from "react-router-dom";
import { Fragment } from "react";

const Navigation = () => {
  return (
    <Fragment>
      <Navbar bg="dark" variant="dark" expand="md">
        <Container>
          <Navbar.Brand href="#home">Tools Network</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/">
                Home
              </Nav.Link>
              <Nav.Link as={Link} to="/colorizer">
                Colorizer
              </Nav.Link>
              <Nav.Link as={Link} to="/toonify">
                Toonify
              </Nav.Link>
              <Nav.Link as={Link} to="/hex-to-string">
                Hex To Text
              </Nav.Link>
              <Nav.Link as={Link} to="/string-to-hex">
                Text To Hex
              </Nav.Link>
              <Nav.Link as={Link} to="/sherlock">
                Sherlock
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet />
    </Fragment>
  );
};

export default Navigation;
