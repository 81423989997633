import { Helmet, HelmetProvider } from "react-helmet-async";

const CustomHeader = (props) => {
  return (
    <HelmetProvider>
      <Helmet>
        {props.title ? (
          <title>{props.title} - Tools Network</title>
        ) : (
          <title>Tools Network</title>
        )}
      </Helmet>
    </HelmetProvider>
  );
};

export default CustomHeader;
