import { Form, Button, Spinner } from "react-bootstrap";
import { useState } from "react";
import axios from "axios";

import CustomHeader from "../../components/custom-header/custom-header";

import "./sherlock.styles.scss";

const API_URL = process.env.REACT_APP_SHERLOCK_URL;

function parseResponse(data, user) {
  let parsed = (data = data.substring(26+user.length, data.length));
  parsed = parsed.substring(0, parsed.indexOf('\n[*]'));
  const resultArray = parsed.split("[+] ");
  resultArray.splice(0, 1);
  let jsonArray = [];
  resultArray.map((r) => {
    const site = r.substring(0, r.indexOf(": http"));
    const url = r.substring(r.indexOf(site) + site.length + 2).replace("\n", "");
    const singleSite = {
      siteName: site,
      siteUrl: url,
    };
    jsonArray.push(singleSite);
  });
  return jsonArray;
}

const Sherlock = () => {
  const [loading, setLoading] = useState();
  const [result, setResult] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const onSubmit = (event) => {
    event.preventDefault();
    setResult(null);
    setLoading(true);
    const json = JSON.stringify({
      args: `${event.target[0].value} --timeout 3`,
    });
    axios
      .post(API_URL, json)
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          const myArray = parseResponse(response.data.output, event.target[0].value);
          setResult(myArray);
        } else {
          setErrorMessage(
            "Something went wrong, please try again later! " +
              response.statusText
          );
        }
      })
      .catch(function (error) {
        setLoading(false);
      });
  };

  return (
    <div className="sherlock-container">
      <CustomHeader title="Sherlock" />
      <h1>Sherlock</h1>
      <p>
        Sherlock is a powerful OSINT tool by{" "}
        <a target="_blank" href="https://sherlock-project.github.io/">
          Sherlock Project
        </a>
        . It can be used to find usernames across many social networks.
      </p>
      <h3>Want to lookup a username?</h3>
      <Form onSubmit={onSubmit} className="search-form">
        <Form.Group className="mb-3">
          <Form.Label>Enter the username below and press Search!</Form.Label>
          <Form.Control type="text" placeholder="Enter username" />
        </Form.Group>
        {loading ? (
          <div>
            <p>Please wait, a search might take up to 20 seconds...</p>
            <Button variant="primary" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">Loading...</span>
            </Button>
          </div>
        ) : (
          <Button variant="primary" type="submit">
            Search
          </Button>
        )}
      </Form>
      {result && (
        <div className="finished-container">
          <p>Found {result.length} sites.</p>
          {result.map((r) => {
            return (
              <p key={r.siteName}>
                {r.siteName} -{" "}
                <a target="_blank" href={r.siteUrl}>
                  {r.siteUrl}
                </a>
              </p>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Sherlock;
