import { Form, Button } from "react-bootstrap";
import { useState } from "react";
import { Buffer } from "buffer";

import CustomHeader from "../../components/custom-header/custom-header";

import "./text-to-hex.styles.scss";

const TextToHex = () => {
  const [hex, setHex] = useState("");
  const [hexText, setHexText] = useState("");

  const onTextChange = (event) => {
    setHex(event.target.value);
    //const output = Buffer.from(event.target.value, "hex");
    const output = Buffer.from(event.target.value, "utf8");
    setHexText(output.toString("hex"));
  };

  return (
    <div className="string-to-hex-container">
      <CustomHeader title="Text To Hex" />
      <h1>Text To Hex String</h1>
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Text:</Form.Label>
          <Form.Control as="textarea" rows={5} onChange={onTextChange} />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Hex:</Form.Label>
          <Form.Control as="textarea" rows={5} disabled value={hexText} />
        </Form.Group>
      </Form>
      <Button
        variant="primary"
        onClick={() => {
          navigator.clipboard.writeText(hexText);
        }}
      >
        Copy hex
      </Button>
    </div>
  );
};

export default TextToHex;
